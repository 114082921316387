import { breakpoints, debounce } from "./utilities.js";
let titlePositions = {};

const getDeviceType = () => {
    if (!titlePositions || !titlePositions.Positions) {
        return false;
    }

    const width = window.innerWidth;
    const device = width < breakpoints.tablet ? "Mobile" :
        (width >= breakpoints.tablet && width < breakpoints.desktop) ? "Tablet" :
            (width >= breakpoints.desktop && width < breakpoints.large) ? "Desktop" :
                (width >= breakpoints.large && width < breakpoints.exlarge) ? "Large" : "ExLarge";

    const bannerTitlePosition = titlePositions.Positions[device];
    if (!bannerTitlePosition) {
        return false
    }

    document.querySelectorAll("[class^='m-banner-hero__banner-container__title--']").forEach(function(f) {
        f.style.left = (bannerTitlePosition.x ?? 50) + '%';
        f.style.top = (bannerTitlePosition.y ?? 20) + '%';
    });
};

const init = (positions) => {
    titlePositions = positions;
    getDeviceType();
    window.addEventListener("resize", debounce(getDeviceType), { 'passive': true });
}

export default init;
